import React, { useState, useEffect } from "react"
import { useForm, Controller, EventType } from "react-hook-form"
import { Account, Employment } from "../../../Utils/Entities"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import dateFormat, { masks, moment } from "dateformat"
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import {
  fetchApi,
  fetchApiAnonymous,
  patchApi,
  patchApiAnonymous,
} from "../../../Utils/Api.utils"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { Loading } from "../../../Utils/Loading"
import MyPageLayout from "../../common/layouts/myPageLayout"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import {
  DateTimeToDate,
  DateTimeToNorwegian,
} from "../../../Utils/DateFormater"
import { toast } from "react-toastify"
import { MyEventsType } from "../../mypagecomponents/event/EventType"
// import { parseISO } from "date-fns"
registerLocale("nb", nb)

type FormValues = Employment

const EditWorkContent = props => {
  const workId = props.props
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)
  const [empName, setEmpName] = useState<string>()
  const [placeName, setPlaceName] = useState<string>()
  const [posName, setPosName] = useState<string>()

  const [employment, setEmployment] = useState<Employment>()

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [showPos, setShowPos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)
  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [roleName, setRoleName] = useState<string>()

  const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [response, setResponse] = useState<MyEventsType | undefined>(undefined)

  const [timer, setTimer] = useState<number>(2500)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setResponse(data)
        if (data?.eventRegistration?.length > 0) setTimer(6000)
      },
    }
  )

  useEffect(() => {
    const getEmployment = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Employments/${workId}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.role === 125600000) setRoleName("Hovedarbeidsgiver")
          else setRoleName("Biarbeidsgiver")
          setEmployment(response.data)
          if (response.data.startDate != undefined)
            setStartDate(response.data.startDate)
          if (response.data.endDate != undefined)
            setEndDate(response.data.endDate)
          if (response.data.employer != undefined) {
            fetchApi(
              `${process.env.GATSBY_API_URL}/Accounts/${response.data.employer}`,
              account,
              inProgress,
              instance
            ).then(r => {
              setEmpName(r.data.name)
              if (response.data.position != undefined) {
                r.data.tariff.positions.forEach(p => {
                  if (p.id === response.data.position) setPosName(p.jobTitle)
                })
                setShowPos(true)
              } else {
                setShowPos(false)
                setIsPAlt(true)
              }
            })
          } else setAGiverFri(true)
          if (response.data.workPlace != undefined)
            fetchApi(
              `${process.env.GATSBY_API_URL}/Accounts/${response.data.workPlace}`,
              account,
              inProgress,
              instance
            ).then(r => {
              setPlaceName(r.data.name)
            })
          else setAStedFri(true)
          reset(response.data)
        })
        .catch(error => {})
    }
    getEmployment()
    const getSub = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        if (
          response?.data[0]?.membershipCategory?.name === "Student" &&
          response?.data[0]?.parentOrganizationUnit?.id ===
            "1331728e-2078-ec11-8d21-000d3aa9614b"
        ) {
          setSMedlem(true)
        }
      })
    }
    getSub()
  }, [])

  const mutationUpdateWork = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${workId}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          // if (employment?.role === 125600000) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
          // }
        }
        window.setTimeout(() => {
          navigate("/app/profil/arbeidsforhold")
        }, timer)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const patchWork = async (data, event) => {
    const end = new Date(data.endDate)
    event.preventDefault()
    if (data.endDate != null || data.endDate != undefined) {
      if (startDate !== undefined && startDate >= end) {
        setInvalidDate(true)
      } else {
        setInvalidDate(false)
        data.endDate = end
        // patchApi(
        //   `${process.env.GATSBY_API_URL}/Employments/${workId}`,
        //   {
        //     endDate: end,
        //   },
        //   account,
        //   inProgress,
        //   instance
        // )
        //   .then(response => {
        //     // console.log(response)
        //     window.setTimeout(() => {
        //       navigate("/app/profil/arbeidsforhold")
        //     }, 2500)
        //   })
        //   .catch(error => {})
        mutationUpdateWork.mutate(data)
      }
    } else {
      navigate("/app/profil/arbeidsforhold")
    }
  }

  return (
    <MyPageLayout2 props="Rediger Arbeidsforhold">
      <div className="">
        <form
          method="PATCH"
          onSubmit={handleSubmit(patchWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {smedlem ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="role"
                        id="role"
                        readOnly={true}
                        key="role"
                        defaultValue={roleName}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      ></input>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="role"
                        id="role"
                        readOnly={true}
                        key="role"
                        defaultValue={roleName}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      ></input>
                    </div>
                  </div>
                )}

                {aGiverFri ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers navn:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="employerAlternative"
                          id="employerAlternative"
                          readOnly={true}
                          defaultValue={employment?.employerAlternative}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workTelephone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers telefon:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          readOnly={true}
                          defaultValue={employment?.workTelephone}
                          name="workTelephone"
                          id="workTelephone"
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternativeAddress"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          rows={3}
                          id="employerAlternativeAddress"
                          readOnly={true}
                          defaultValue={employment?.employerAlternativeAddress}
                          // required
                          name="employerAlternativeAddress"
                          className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="employer"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsgiver:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="employer"
                        id="employer"
                        key="employer"
                        readOnly={true}
                        defaultValue={empName}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                )}

                {AStedFri ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets navn:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="workPlaceAlternative"
                          id="workPlaceAlternative"
                          readOnly={true}
                          defaultValue={employment?.workPlaceAlternative}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceTelephone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets telefon:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          readOnly={true}
                          name="workPlaceTelephone"
                          id="workPlaceTelephone"
                          defaultValue={employment?.workPlaceTelephone}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceAlternativeAddress"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          rows={3}
                          id="workPlaceAlternativeAddress"
                          readOnly={true}
                          defaultValue={employment?.workPlaceAlternativeAddress}
                          // required
                          name="workPlaceAlternativeAddress"
                          className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlace"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidssted:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="workPlace"
                        id="workPlace"
                        key="workPlace"
                        readOnly={true}
                        defaultValue={placeName}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                )}

                {showPos ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Stilling:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="position"
                          id="position"
                          key="position"
                          readOnly={true}
                          value={posName}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {isPAlt ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlaceAlternative"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stilling (fritekst):
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="positionAlternative"
                        id="positionAlternative"
                        readOnly={true}
                        defaultValue={employment?.positionAlternative}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="partTimePosition"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prosentvis stiling:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="partTimePosition"
                      readOnly={true}
                      defaultValue={employment?.partTimePosition}
                      id="partTimePosition"
                      className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          readOnly={true}
                          selected={Date.parse(value)}
                          dateFormat="dd/MM/yyyy"
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Slutt dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={Date.parse(value)}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5 flex justify-end">
                <button
                  type="button"
                  onClick={() => navigate("/app/profil/arbeidsforhold")}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Tilbake
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Lagre
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="mb-2 flex justify-center">
          {invalidDate ? (
            <p className="text-red-500">
              Start dato kan ikke være etter slutt dato.
            </p>
          ) : (
            <div></div>
          )}
        </div>
        <div className="mb-2 flex justify-center">
          {invalidDate ? (
            <p className="text-red-500">
              Start dato kan ikke være etter slutt dato.
            </p>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </MyPageLayout2>
  )
}

const EditWork = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const workId = location?.state?.workId
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditWorkContent props={workId} />
    </MsalAuthenticationTemplate>
  )
}

export default EditWork
