import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { createContext, ReactChild, useEffect, useState } from "react"
import { useQuery, UseQueryResult } from "react-query"
import { StringParam, useQueryParam } from "use-query-params"
import {
  fetchApi,
  fetchApiAnonymous,
  postApi,
  postApiNoToast,
} from "../../../../Utils/Api.utils"
import { Contact } from "../../../../Utils/Entities"
import { StepsType } from "../../../common/Steps/StepsType"
import {
  CompanyPayerType,
  ContactOtherPayer,
  CustomRegistrationField,
  EventType,
  FieldEnum,
  OtherPayerType,
  PayerInfo,
  RegistrationRequestType,
  SessionToPassType,
  SessionType,
  WorkplaceType,
} from "../EventType"
import { EventRegistrationTypeEnum, PayerType } from "../helpers/Event"
import { QuestionnaireAnswersType } from "./InnmeldingType"
import { toast } from "react-toastify"
import { AxiosResponse } from "axios"

export const InnmeldingContext = createContext<
  innmeldingContextType | undefined
>(undefined)

const InnmeldingProvider = ({ children }: { children: ReactChild }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventId = useQueryParam("id", StringParam)[0]
  const [payerType, setPayerType] = useState<string>("")
  const [registrationReson, setRegistrationReson] = useState<string>("")
  const [otherPayerData, setOtherPayerData] = useState<Array<OtherPayerType>>(
    []
  )
  const [companyPayerData, setCompanyPayerData] = useState<
    Array<CompanyPayerType>
  >([])
  const [registrationType, setRegistrationType] = useState<number>(0)
  const [workplace, setWorkplace] = useState<WorkplaceType | undefined>(
    undefined
  )
  const [contact, setContact] = useState<Contact | undefined>(undefined)
  const [steps, setSteps] = useState<StepsType[]>(initSteps)
  const [selectedSessions, setSelectedSessions] = useState<Array<string>>([])
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [selectedWorkplace, setSelectedWorkplace] = useState<string>("")
  const [questionnaireAnswers, setQuestionnaireAnswers] =
    useState<QuestionnaireAnswersType>(new Map())
  const [sessionToPass, setSessionToPass] = useState<Array<SessionToPassType>>(
    []
  )
  const [submitingData, setSubmitingData] = useState<boolean>(false)

  const sessionQuery = useQuery<Array<SessionType>>(
    "session-" + eventId,
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL + `/Events/${eventId}/GetSession`
      ).then(res => res.data)
  )

  const registrationFieldsQuery = useQuery<Array<CustomRegistrationField>>(
    "regField-" + eventId,
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${eventId}/GetCustomRegistrationFields`
      ).then(res => res.data)
  )

  const eventQuery = useQuery<EventType>("event-" + eventId, async () =>
    fetchApiAnonymous(process.env.GATSBY_API_URL + `/Events/${eventId}`).then(
      res => res.data
    )
  )

  useQuery<{ registrationType: number }>(
    ["session-" + eventId, eventQuery.data?.customFields?.eventType?.id],
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/registrationType/${eventQuery.data?.customFields?.eventType?.id}`
      ).then(res => res.data),
    {
      enabled: !!eventQuery.data?.customFields?.eventType?.id,
      onSuccess: data => {
        setRegistrationType(data.registrationType)
      },
    }
  )

  useQuery<Array<SessionToPassType>>(
    ["passes-" + eventId, registrationType],
    async () => {
      const data =
        registrationType !== EventRegistrationTypeEnum.noSessions
          ? await fetchApi(
              process.env.GATSBY_API_URL + `/Events/${eventId}/GetPass`,
              account,
              inProgress,
              instance
            )
          : { data: [] }

      return data.data
    },
    {
      enabled: !!registrationType,
      onSuccess: data => {
        setSessionToPass(data)
      },
    }
  )

  useQuery<Contact>(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
      },
      onError: _error => {},
    }
  )

  useQuery<ContactOtherPayer>(
    "otherPayerData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/OtherPayer",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setOtherPayerData([
          { id: "name", payload: data.name || "" },
          { id: "orgNr", payload: data.orgNr || "" },
          { id: "invoiceAdr", payload: data.invoiceAdr || "" },
          { id: "zipCode", payload: data.zipCode?.postalCode || "" },
        ])
      },
      onError: _error => {},
    }
  )

  const Submit = async (e: any) => {
    setSubmitingData(true)
    let postObj: RegistrationRequestType = {}

    const payerMap = new Map<string, number | undefined>()
    payerMap.set("Privat", PayerType.privatePayer)
    payerMap.set("Arbeidsgiver", PayerType.companyPayer)
    payerMap.set("Annen", PayerType.otherPayer)
    payerMap.set("", undefined)

    let payerInfo: PayerInfo = {}

    if (payerType === "Arbeidsgiver") {
      payerInfo = {
        invoiceRef1:
          companyPayerData.find(item => item.id === "invoiceRef1")?.payload ||
          "",
        invoiceRef2:
          companyPayerData.find(item => item.id === "invoiceRef2")?.payload ||
          "",
      }
    } else if (payerType === "Annen") {
      payerInfo = {
        name: otherPayerData.find(item => item.id === "name")?.payload || "",
        orgNr: otherPayerData.find(item => item.id === "orgNr")?.payload || "",
        invoiceAdr:
          otherPayerData.find(item => item.id === "invoiceAdr")?.payload || "",
        invoiceRef1:
          otherPayerData.find(item => item.id === "invoiceRef1")?.payload || "",
        invoiceRef2:
          otherPayerData.find(item => item.id === "invoiceRef2")?.payload || "",
        zipCode:
          otherPayerData.find(item => item.id === "zipCode")?.payload || "",
      }
    }

    if (
      registrationType === EventRegistrationTypeEnum.noSessions ||
      registrationType === EventRegistrationTypeEnum.requireAll
    ) {
      postObj = {
        attendees: [
          {
            payerType: payerMap.get(payerType),
            payerInfo: payerInfo,
            registrationReason:
              registrationReson === "" ? undefined : registrationReson,
            responses:
              questionnaireAnswers.get("1")?.map(item => {
                return { id: item.id, value: item.value }
              }) || [],
          },
        ],
      }
    }
    if (registrationType === EventRegistrationTypeEnum.optional) {
      postObj = {
        attendees: [
          ...selectedSessions.map(item => {
            return {
              payerType: payerMap.get(payerType),
              payerInfo: payerInfo,
              // registrationReason:
              //   registrationReson === "" ? undefined : registrationReson,
              passId: sessionToPass.find(x => x.sessionId === item)
                ?.passMembership[0].passId,
              responses:
                questionnaireAnswers.get(item)?.map(item => {
                  return { id: item.id, value: item.value }
                }) || [],
              attendeeSessions: [{ sessionId: item, waitlisted: false }],
            }
          }),
        ],
      }
    }

    // let res: AxiosResponse<any, any>
    try {
      const res = await postApi(
        process.env.GATSBY_API_URL + `/Events/${eventId}`,
        postObj,
        account,
        inProgress,
        instance
      )
      // .catch(error => {
      //   if (
      //     error.response.status === 400 &&
      //     error.response?.data === "venligst oppgi grunn til påmeldingen"
      //   ) {
      //     setSubmitingData(false)

      //     toast.warn("venligst oppgi grunn til påmeldingen og prøv igjen", {
      //       autoClose: 10000,
      //     })

      //     setSteps(prev =>
      //       prev.map(step => {
      //         if (step.id === "Grunn") return { ...step, status: "enabled" }
      //         return step
      //       })
      //     )

      //     e.callback()
      //   }

      //   if (error.response.status === 409) {
      //     setSubmitingData(false)
      //     toast.error(
      //       "Du kan ikke ha mer enn to kurspåmeldinger med fremtidig start. Ta kontakt med kursavdelingen for bistand ved spørsmål eller feil",
      //       {
      //         autoClose: 10000,
      //       }
      //     )
      //     // navigate(`/app/kurs/minekurs`)
      //   }
      // })

      // if (res.status === 400) {
      //   setSubmitingData(false)

      //   console.log("error", res.data)

      //   toast.warn("venligst oppgi grunn til påmeldingen og prøv igjen")

      //   setSteps(prev =>
      //     prev.map(step => {
      //       if (step.id === "Grunn") return { ...step, status: "enabled" }
      //       return step
      //     })
      //   )

      //   e.callback()
      // }

      if (res?.status === 200) {
        setSubmitingData(false)
        navigate(`/app/kurs/bekreftelse?id=${eventId}`)
      }

      // if (res.status === 409) {
      //   setSubmitingData(false)
      //   toast.error(
      //     "Du kan ikke ha mer enn to kurspåmeldinger med fremtidig start. Ta kontakt med kursavdelingen for bistand ved spørsmål eller feil"
      //   )
      //   navigate(`/app/kurs`)
      // }
    } catch (error) {
      //@ts-ignore
      // if (error?.response?.status === 400) {
      //   setSubmitingData(false)
      //   toast.warn("venligst oppgi grunn til påmeldingen og prøv igjen")
      //   setSteps(prev =>
      //     prev.map(step => {
      //       if (step.id === "Grunn") return { ...step, status: "enabled" }
      //       return step
      //     })
      //   )
      //   e.callback()
      // } else {
      //   setSubmitingData(false)
      //   toast.error(
      //     "Du kan ikke ha mer enn to kurspåmeldinger med fremtidig start. Ta kontakt med kursavdelingen for bistand ved spørsmål eller feil"
      //   )
      //   navigate(`/app/kurs`)
      // }
    }
  }

  useQuery<WorkplaceType>(
    ["workplace"],
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Contacts/Employer`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setWorkplace(data)
        setSelectedWorkplace(data?.id || "")
      },
    }
  )

  const getCurrentStep = (): StepsType | undefined =>
    steps.filter(item => item.status === "enabled")[currentStepIndex]

  const isNextEnabled = () => getCurrentStep()?.completed === true

  const updateStepsStatus = () => {
    //@ts-ignore: Type 'string' is not assignable to type '"enabled" | "disabled" | "init"
    setSteps(prev => {
      const test = prev.map(step => {
        if (step.id === "kursvelger") {
          const status =
            (sessionQuery.data || []).length > 0 ? "enabled" : "disabled"
          return { ...step, status: status }
        } else if (step.id === "kursspm") {
          const status =
            (registrationFieldsQuery.data || []).length > 0
              ? "enabled"
              : "disabled"
          return { ...step, status: status }
        } else {
          return step
        }
      })
      return test
    })
  }

  useEffect(() => {
    updateStepsStatus()
  }, [sessionQuery.data, registrationFieldsQuery.data, selectedSessions])

  useEffect(() => {
    stepsCheckCompleted()
  }, [
    sessionQuery.data,
    selectedSessions,
    questionnaireAnswers,
    contact,
    workplace,
    payerType,
  ])

  const stepsCheckCompleted = () => {
    setSteps(prev =>
      prev.map(step => {
        if (step.id === "kursvelger") {
          const completed = selectedSessions.length > 0
          return { ...step, completed: completed }
        }
        if (step.id === "kursspm") {
          const completed = true // TODO: check if all required kursspms are selected
          return { ...step, completed: completed }
        }
        if (step.id === "betaling") {
          let test = true
          if (payerType === "Arbeidsgiver") {
            if (selectedWorkplace === "") test = false
          }

          return { ...step, completed: test }
        }
        return step
      })
    )
  }

  const addSession = (id: string): Array<string> => {
    const temp = [...selectedSessions, id]
    setSelectedSessions(temp)
    return temp
  }
  const removeSession = (id: string) => {
    const temp = selectedSessions.filter(item => item !== id)
    setSelectedSessions(temp)
    return temp
  }

  const updateRespone = (
    id: string,
    payload: string,
    sessionId: string,
    fieldType: FieldEnum
  ) => {
    const tempId = id.toString()

    const parsedPayload =
      fieldType === FieldEnum.Boolean
        ? payload === "ja"
          ? "Yes"
          : "No"
        : payload

    const data = questionnaireAnswers.get(sessionId)
    if (!data) {
      setQuestionnaireAnswers(prev => {
        const temp = new Map(prev)
        temp.set(sessionId, [{ id: tempId, value: parsedPayload }])
        return temp
      })
    } else {
      let temp: Array<{ id: string; value: string }>

      if (data.find(item => item.id === tempId)) {
        // update existing
        temp = data.map(item => {
          if (item.id === tempId) {
            item.value = parsedPayload
          }
          return item
        })
      } else {
        // append new
        temp = [...data, { id: tempId, value: parsedPayload }]
      }

      setQuestionnaireAnswers(prev => {
        const temp2 = new Map(prev)
        temp2.set(sessionId, temp)
        return temp2
      })
    }
  }

  const store: innmeldingContextType = {
    eventId: eventId,
    stepsData: [steps, setSteps],
    stepsControl: {
      getCurrentStep,
      isNextEnabled,
      stepsCheckCompleted,
    },
    registrationType: [registrationType, setRegistrationType],
    selectedSessions: [selectedSessions, setSelectedSessions],
    currentStepIndex: [currentStepIndex, setCurrentStepIndex],
    questionnaireAnswers: [questionnaireAnswers, setQuestionnaireAnswers],
    selectedWorkplace: [selectedWorkplace, setSelectedWorkplace],
    contact: [contact, setContact],
    submitingData: [submitingData, setSubmitingData],
    workplace: workplace,
    payerType: [payerType, setPayerType],
    otherPayerData: [otherPayerData, setOtherPayerData],
    companyPayerData: [companyPayerData, setCompanyPayerData],
    addSession: addSession,
    removeSession: removeSession,
    updateRespone: updateRespone,
    sessionQuery: sessionQuery.data,
    sessionQueryStatus: sessionQuery,
    eventQuery: eventQuery.data,
    registrationFieldsQuery: registrationFieldsQuery.data,
    registrationFieldsQueryStatus: registrationFieldsQuery,
    registrationReson: [registrationReson, setRegistrationReson],
    Submit: Submit,
  }

  return (
    <InnmeldingContext.Provider value={store}>
      {children}
    </InnmeldingContext.Provider>
  )
}

export type innmeldingContextType = {
  eventId: string | null | undefined
  selectedSessions: [string[], React.Dispatch<React.SetStateAction<string[]>>]
  currentStepIndex: [number, React.Dispatch<React.SetStateAction<number>>]
  stepsData: [StepsType[], React.Dispatch<React.SetStateAction<StepsType[]>>]
  registrationType: [number, React.Dispatch<React.SetStateAction<number>>]
  workplace?: WorkplaceType
  payerType: [string, React.Dispatch<React.SetStateAction<string>>]
  selectedWorkplace: [string, React.Dispatch<React.SetStateAction<string>>]
  submitingData: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  registrationReson: [string, React.Dispatch<React.SetStateAction<string>>]
  otherPayerData: [
    Array<OtherPayerType>,
    React.Dispatch<React.SetStateAction<Array<OtherPayerType>>>
  ]
  companyPayerData: [
    Array<CompanyPayerType>,
    React.Dispatch<React.SetStateAction<Array<CompanyPayerType>>>
  ]
  contact: [
    Contact | undefined,
    React.Dispatch<React.SetStateAction<Contact | undefined>>
  ]
  stepsControl: {
    getCurrentStep: () => StepsType | undefined
    isNextEnabled: () => boolean
    stepsCheckCompleted: () => void
  }
  questionnaireAnswers: [
    QuestionnaireAnswersType,
    React.Dispatch<React.SetStateAction<QuestionnaireAnswersType>>
  ]
  addSession: (id: string) => string[]
  removeSession: (id: string) => string[]
  updateRespone: (
    id: string,
    payload: string,
    eventId: string,
    fieldType: number
  ) => void
  sessionQuery: SessionType[] | undefined
  sessionQueryStatus: UseQueryResult<SessionType[], unknown>
  eventQuery: EventType | undefined
  registrationFieldsQuery: CustomRegistrationField[] | undefined
  registrationFieldsQueryStatus: UseQueryResult<
    CustomRegistrationField[],
    unknown
  >
  Submit: (e: any) => void
  // eventQuery: EventType
}

const initSteps: StepsType[] = [
  {
    id: "persona",
    name: "Kontaktinfo",
    status: "enabled",
    completed: true,
  },
  {
    id: "kursvelger",
    name: "Velg kurs",
    status: "init",
    completed: false,
  },
  {
    id: "kursspm",
    name: "Kursspørsmål",
    status: "init",
    completed: false,
  },
  {
    id: "betaling",
    name: "Betaling",
    status: "enabled",
    completed: true,
  },
  {
    id: "oppsummering",
    name: "Oppsummering",
    status: "enabled",
    completed: true,
  },
  {
    id: "Grunn",
    name: "Påmeldingsinformasjon",
    status: "disabled",
    completed: true,
  },
]

export default InnmeldingProvider
