import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Account, Employment } from "../../../Utils/Entities"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { Loading } from "../../../Utils/Loading"
import { fetchApi, postApi } from "../../../Utils/Api.utils"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import LoadingData from "../../common/graphichs/LoadingData"
import HelperText from "../../common/text/HelperText"
import { classNames } from "../../common/graphichs/misc"
import { Switch } from "@headlessui/react"
registerLocale("nb", nb)

type FormValues = Employment

const NewEmploymentContent = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const queryClient = useQueryClient()

  const [bedrift, setAccounts] = useState<Account[]>()
  const [allWorkPlaces, setAllWP] = useState<Account[]>()
  const [allEmp, setAllEmployers] = useState<Account[]>()
  const [parent, setParent] = useState<string>()
  const [child, setChild] = useState<string>()
  const [position, setPos] = useState<string>()
  const [places, setWorkPlaces] = useState<any[]>()
  const [disableE, setdisableE] = useState<boolean>(false)
  const [disableP, setdisableP] = useState<boolean>(false)
  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)

  const [positions, setPositions] = useState<any[]>()
  const [showPos, setShowPos] = useState<boolean>(true)
  const [disablePos, setDisablePos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)

  const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [timer, setTimer] = useState<number>(2500)
  const [showLoadingData, setShowLoadingData] = useState(false)

  const [disableButton, setDisabled] = useState<boolean>(false)
  const [hideCheckboxPlace, setHideCheckPlace] = useState<boolean>(false)

  const [enabled1, setEnabled1] = useState(false)
  const [enabled2, setEnabled2] = useState(false)
  const [enabled3, setEnabled3] = useState(false)
  const [enabled4, setEnabled4] = useState(false)
  const [enabled5, setEnabled5] = useState(false)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (data?.eventRegistration?.length > 0) setTimer(6000)
      },
    }
  )

  const today = new Date()

  const employersQuery = useQuery(
    "employers",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Employers`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccounts(data)
        setAllEmployers(data)
      },
    }
  )

  const workPlaceQuery = useQuery(
    "workPlaces",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Workplaces`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setWorkPlaces(data)
        setAllWP(data)
      },
    }
  )

  useEffect(() => {
    const getSub = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      })
    }
    getSub()
  }, [])

  const updateWorkPlace = event => {
    setShowPos(false)
    setIsPAlt(false)
    setParent(event.target.value)
    setAGiverFri(false)
    setdisableP(false)
    // getWorkPlaces(event.target.value)
    if (event.target.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${event.target.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.workPlaces.length > 0) {
            setWorkPlaces(response.data.workPlaces)
            setAStedFri(false)
            setdisableP(false)
            setHideCheckPlace(false)
          } else {
            setAStedFri(true)
            setdisableP(true)
            setHideCheckPlace(true)
            setChild("")
          }
          if (response.data.tariff.positions.length > 0) {
            setShowPos(true)
            setPositions(response.data.tariff.positions)
          } else {
            setShowPos(false)
            setIsPAlt(true)
          }
        })
        .catch(error => {})
    } else {
      setAGiverFri(true)
      setWorkPlaces(allWorkPlaces)
      setIsPAlt(true)
    }
  }

  const updateEmployer = event => {
    setdisableE(false)
    setAStedFri(false)
    setChild(event.target.value)
    if (event.target.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${event.target.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.parentEmployerId != undefined) {
            setParent(response.data.parentEmployerId)
            setdisableE(true)
            setAGiverFri(false)
          } else {
            setParent("")
            setdisableE(true)
            setAGiverFri(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setAStedFri(true)
      if (!enabled1) {
        setAccounts(allEmp)
        setdisableE(false)
      }
    }
  }

  const changePos = event => {
    setPos(event.target.value)
  }

  const showEmpAlt = event => {
    setEnabled1(!enabled1)

    if (!enabled1) {
      setParent("")
      setAGiverFri(true)
      setdisableE(true)
      setIsPAlt(true)
      setWorkPlaces(allWorkPlaces)
      setDisablePos(true)
      setShowPos(false)
    } else {
      setIsPAlt(false)
      setDisablePos(false)
      setAGiverFri(false)
      setdisableE(false)
    }
  }
  const showPlaceAlt = event => {
    setEnabled2(!enabled2)

    if (!enabled2) {
      setChild("")
      setAStedFri(true)
      setdisableP(true)
      if (!enabled1) {
        setAccounts(allEmp)
        setdisableE(false)
        setAGiverFri(false)
      }
    } else {
      setAStedFri(false)
      setdisableP(false)
    }
  }
  const showPosAlt = event => {
    setEnabled3(!enabled3)

    if (!enabled3) {
      setPos("")
      setIsPAlt(true)
      setDisablePos(true)
      // if (!enabled1) {
      //   setAccounts(allAccounts)
      //   setdisableE(false)
      //   setAGiverFri(false)
      // }
    } else {
      setIsPAlt(false)
      setDisablePos(false)
    }
  }

  const mutationNewWork = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: data => {
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
        window.setTimeout(() => {
          navigate("/app/profil/arbeidsforhold")
        }, timer)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const postWork = async (data, event) => {
    setShowLoadingData(true)
    event.preventDefault()
    setInvalidDate(false)

    if (aGiverFri) data.employer = ""
    else {
      data.employerAlternative = ""
      data.employer = parent
    }

    if (AStedFri) data.workPlace = ""
    else data.workPlaceAlternative = ""

    if (isPAlt) data.position = ""
    else data.positionAlternative = ""
    if (smedlem) data.role = "125600001"

    // if (data.startDate == null || data.startDate == undefined)
    // {
    //   if(new Date() >= data.endDate) setInvalidDate(true)

    //   data.startDate = new Date()
    // }

    if (data.endDate != null || data.endDate != undefined) {
      if (data.startDate >= data.endDate) {
        setInvalidDate(true)
        setShowLoadingData(false)
      } else {
        setInvalidDate(false)
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          setDisabled(true)
        } else setShowLoadingData(true)
        setDisabled(true)
        mutationNewWork.mutate(data)
      }
    } else {
      if (eventQuery?.data?.eventRegistration?.length > 0) {
        setDisabled(true)
      } else setShowLoadingData(true)
      setDisabled(true)
      mutationNewWork.mutate(data)
    }
  }

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : (
    <MyPageLayout2 props="Nytt Arbeidsforhold">
      {/* <div>
        {showLoadingData ? <LoadingData subject="Oppretter..." /> : null}
      </div> */}
      {/* <div>{showLoadingData && <LoadingData subject="Oppretter..." />}</div> */}
      <div className="">
        <form
          method="POST"
          onSubmit={handleSubmit(postWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {smedlem ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        // {...register("role")}
                        name="role"
                        id="role"
                        key="role"
                        disabled={true}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md bg-gray-50"
                      >
                        {/* <option value={125600000}>Hovedarbeidsgiver</option> */}
                        <option value={125600001}>Biarbeidsgiver</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("role")}
                        name="role"
                        id="role"
                        key="role"
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value={125600000}>Hovedarbeidsgiver</option>
                        <option value={125600001}>Biarbeidsgiver</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="employer"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Arbeidsgiver:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register("employer")}
                      name="employer"
                      id="employer"
                      key="employer"
                      disabled={disableE}
                      // defaultValue={defaultCountryCode}
                      // onChange={isNorwegian}
                      value={parent}
                      onChange={updateWorkPlace}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      <option disabled selected value="">
                        {" "}
                        -- Arbeidsgiver --{" "}
                      </option>
                      {bedrift?.map(a => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                      {/* <option value="Annet">Annet</option> */}
                    </select>
                  </div>
                </div>

                {/* <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                  <HelperText helpertext="Min arbeidsgiver står ikke i listen:" />
                  <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                    <input
                      // id={}
                      // name={}
                      onChange={showEmpAlt}
                      defaultChecked={false}
                      type="checkbox"
                      className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded"
                    />
                  </div>
                </div> */}

                <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                  <HelperText helpertext="Min arbeidsgiver står ikke i listen:" />
                  <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                    <Switch
                      checked={enabled1}
                      onChange={e => showEmpAlt(e)}
                      className={classNames(
                        enabled1 ? "bg-meny-600" : "bg-gray-900",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled1 ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </div>
                </div>

                {aGiverFri ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers navn:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("employerAlternative", {
                            // pattern: {
                            //   value: /^[-A-Za-zÆØÅæøå ]+$/i,
                            //   message:
                            //     "Kun bokstaver og bindestrek er tillatt.",
                            // },
                            minLength: {
                              value: 2,
                              message: "Minimum tegn er 2",
                            },
                            maxLength: {
                              value: 100,
                              message: "Maks tegn er 100",
                            },
                          })}
                          name="employerAlternative"
                          id="employerAlternative"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="employerAlternative"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workTelephone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers telefon:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("workTelephone", {
                            pattern: {
                              value:
                                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
                              message: "Skriv inn et gyldig telefonnummer.",
                            },
                          })}
                          name="workTelephone"
                          id="workTelephone"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="workTelephone"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternativeAddress"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          rows={3}
                          id="employerAlternativeAddress"
                          {...register("employerAlternativeAddress", {
                            maxLength: {
                              value: 850,
                              message: "Maks tegn er 850",
                            },
                          })}
                          // required
                          name="employerAlternativeAddress"
                          className="form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="employerAlternativeAddress"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {hideCheckboxPlace ? (
                  <></>
                ) : (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlace"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidssted:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("workPlace")}
                          name="workPlace"
                          id="workPlace"
                          key="workPlace"
                          disabled={disableP}
                          value={child}
                          // onChange={isNorwegian}
                          onChange={updateEmployer}
                          className=" block w-full shadow-sm focus:ring-meny-500 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- Arbeidssted --{" "}
                          </option>

                          {places?.map(a => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                          {/* <option value="Annet">Annet</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                      <HelperText helpertext="Mitt arbeidssted står ikke i listen:" />
                      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                        <Switch
                          // defaultChecked={false}
                          checked={enabled2}
                          onChange={e => showPlaceAlt(e)}
                          className={classNames(
                            enabled2 ? "bg-meny-600" : "bg-gray-900",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              enabled2 ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  </>
                )}
                {AStedFri ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets navn:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("workPlaceAlternative", {
                            // pattern: {
                            //   value: /^[-A-Za-zÆØÅæøå ]+$/i,
                            //   message:
                            //     "Kun bokstaver og bindestrek er tillatt.",
                            // },
                            minLength: {
                              value: 2,
                              message: "Minimum tegn er 2",
                            },
                            maxLength: {
                              value: 100,
                              message: "Maks tegn er 100",
                            },
                          })}
                          name="workPlaceAlternative"
                          id="workPlaceAlternative"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="workPlaceAlternative"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceTelephone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets telefon:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("workPlaceTelephone", {
                            pattern: {
                              value:
                                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
                              message: "Skriv inn et gyldig telefonnummer.",
                            },
                          })}
                          name="workPlaceTelephone"
                          id="workPlaceTelephone"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="workPlaceTelephone"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceAlternativeAddress"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsstedets adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          rows={3}
                          id="workPlaceAlternativeAddress"
                          {...register("workPlaceAlternativeAddress", {
                            maxLength: {
                              value: 850,
                              message: "Maks tegn er 850",
                            },
                          })}
                          // required
                          name="workPlaceAlternativeAddress"
                          className="form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="workPlaceAlternativeAddress"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {showPos ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Stilling:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("position")}
                          name="position"
                          id="position"
                          key="position"
                          value={position}
                          onChange={changePos}
                          disabled={disablePos}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- Stilling --{" "}
                          </option>

                          {positions?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.jobTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                      <HelperText helpertext="Min stilling står ikke i listen:" />
                      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                        <Switch
                          checked={enabled3}
                          onChange={e => showPosAlt(e)}
                          className={classNames(
                            enabled3 ? "bg-meny-600" : "bg-gray-900",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              enabled3 ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {isPAlt ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlaceAlternative"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stilling (fritekst):
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("positionAlternative", {
                          pattern: {
                            value: /^[-A-Za-zÆØÅæøå ]+$/i,
                            message: "Kun bokstaver og bindestrek er tillatt.",
                          },
                          minLength: {
                            value: 2,
                            message: "Minimum tegn er 2",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maks tegn er 100",
                          },
                        })}
                        name="positionAlternative"
                        id="positionAlternative"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="positionAlternative"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="partTimePosition"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prosentvis stiling:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("partTimePosition", {
                        pattern: {
                          value: /^[1-9][0-9]?$|^100$/i,
                          message: "Kun tall mellom 1-100 er tillatt",
                        },
                      })}
                      name="partTimePosition"
                      placeholder="1-100"
                      defaultValue={100}
                      id="partTimePosition"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="partTimePosition"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={new Date()}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          id="startDate"
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Slutt dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                    {/* <input type="submit" /> */}
                  </div>
                </div>
              </div>
              <div className="pt-5 flex justify-end">
                <button
                  type="button"
                  // disabled={disableButton}
                  onClick={() => navigate("/app/profil/arbeidsforhold")}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Tilbake
                </button>
                <button
                  type="submit"
                  disabled={disableButton}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Lagre
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="mb-2 flex justify-center">
          {invalidDate ? (
            <p className="text-red-500">
              Start dato kan ikke være etter slutt dato.
            </p>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </MyPageLayout2>
  )
}

const NewEmployment = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewEmploymentContent />
    </MsalAuthenticationTemplate>
  )
}

export default NewEmployment
