import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { fetchApi } from "../../../Utils/Api.utils"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../../security/authConfig"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { EuropesyCertificate } from "../../../Utils/Types"
import {
  CertificateFieldsOfPractice,
  CertificateTypeOfCertificate,
} from "../../../Utils/Maps"

const MyEuropsyCertificatesContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [certificates, setCertificates] = useState<EuropesyCertificate[]>([])

  const userCertificatesQuery = useQuery(
    "userCertificateData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Europsy/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCertificates(data)
      },
      onError: _error => {},
    }
  )

  const sortedCertificates = certificates.sort((a, b) => {
    return (
      new Date(b.approvedTo || "").getTime() -
      new Date(a.approvedTo || "").getTime()
    )
  })

  return userCertificatesQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userCertificatesQuery.isError ? (
    userCertificatesQuery.error.message
  ) : (
    <MyPageLayout props="Sertifiseringer">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {certificates.length > 0 ? (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-meny-700">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-meny-50 sm:pl-6 "
                        >
                          Type sertifikat
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Praksisområde
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Registreringsnr
                        </th>
                        <th
                          scope="col"
                          className="table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Godkjent fra
                        </th>
                        <th
                          scope="col"
                          className="table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Godkjent til
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sortedCertificates.map(certificate => (
                        <tr
                          key={certificate?.id}
                          className="  hover:text-meny-50 hover:bg-gray-200 focus:outline-none focus:ring-2"
                        >
                          <td className="whitespace-normal py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {CertificateTypeOfCertificate.get(
                                    certificate?.typeOfSertificate
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {certificate?.fieldsOfPractices !== undefined
                              ? certificate?.fieldsOfPractices
                                  ?.split(",")
                                  .map(value =>
                                    CertificateFieldsOfPractice.get(
                                      parseInt(value)
                                    )
                                  )
                                  .join(", ")
                              : null}
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {certificate?.registrationNumber}
                          </td>
                          <td className="table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {certificate?.approvedFrom !== undefined ? (
                              <time dateTime={certificate?.approvedFrom}>
                                {new Intl.DateTimeFormat("nb-NO", {
                                  dateStyle: "medium",
                                }).format(new Date(certificate?.approvedFrom))}
                              </time>
                            ) : null}
                          </td>
                          <td className="table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {certificate?.approvedTo !== undefined ? (
                              <time dateTime={certificate?.approvedTo}>
                                {new Intl.DateTimeFormat("nb-NO", {
                                  dateStyle: "medium",
                                }).format(new Date(certificate?.approvedTo))}
                              </time>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  Du har ingen sertifiseringer.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </MyPageLayout>
  )
}

const EuropsyCertificate = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyEuropsyCertificatesContent />
    </MsalAuthenticationTemplate>
  )
}

export default EuropsyCertificate
