import React, { useContext, useEffect, useState } from "react"
import { EventRegistrationTypeEnum } from "../helpers/Event"
import { Card } from "../../../common/card"
import {
  BooleanChoice,
  MultipleChoice,
  SimpleText,
  SingleChoice,
} from "../../../common/inputFields"
import { InputFieldsActionsType } from "../../../common/inputFields/InputFieldsType"
import { SessionType } from "../EventType"
import { InnmeldingContext } from "./dataStorage"

const Kursspm = () => {
  const [sessions, setSessions] = useState<Array<SessionType>>([])
  const InnmeldingData = useContext(InnmeldingContext)

  if (!InnmeldingData) return <div></div>

  useEffect(() => {
    const updateSession = () => {
      if (
        InnmeldingData.registrationType[0] ===
          EventRegistrationTypeEnum.noSessions ||
        InnmeldingData.registrationType[0] ===
          EventRegistrationTypeEnum.requireAll
      ) {
        setSessions([
          {
            id: "1",
            name: InnmeldingData.eventQuery?.eventName,
          },
        ])
        return
      }
      if (!InnmeldingData.sessionQuery) return
      setSessions(
        InnmeldingData.sessionQuery.filter(item =>
          InnmeldingData.selectedSessions[0].includes(item.id)
        )
      )
    }

    updateSession()
  }, [InnmeldingData.selectedSessions[0], InnmeldingData.sessionQuery])

  const control = (
    action: InputFieldsActionsType,
    id: string,
    sessionId: string,
    fieldType: number,
    payload?: string
  ) => {
    if (action === "add") {
      const data = InnmeldingData.questionnaireAnswers[0]
        .get(sessionId)
        ?.find(item => item.id === id)?.value

      InnmeldingData.updateRespone(
        id,
        (data || "") + "\n" + payload,
        sessionId,
        fieldType
      )
    } else if (action === "remove") {
      //find object with given eventId
      //find answer with id in given object with eventId
      //split the string eg. "one\ntwo\nthree" into -> ["one","two","three"]
      //remove payload eg two -> ["one", "three"] then combine -> "one\ntwo"
      const data = InnmeldingData.questionnaireAnswers[0]
        .get(sessionId)
        ?.find(item => item.id === id)
        ?.value?.split("\n")
        .filter(item => item !== payload)
        .join("\n")

      InnmeldingData.updateRespone(id, data || "", sessionId, fieldType)
    } else {
      //update
      InnmeldingData.updateRespone(id, payload || "", sessionId, fieldType)
    }
  }

  const getSelectedValue = (
    sessionId: string,
    customRegistrationFieldId: string
  ): string => {
    return (
      InnmeldingData.questionnaireAnswers[0]
        .get(sessionId)
        ?.find(ans => ans.id === customRegistrationFieldId)?.value || ""
    )
  }

  return (
    <div>
      <h2>Kursspørsmål</h2>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="space-y-2">
        {sessions.map((item, index) => (
          <Card key={index} title={item.name}>
            <div className="grid grid-cols-1 md:grid-cols-2">
              {InnmeldingData.registrationFieldsQuery?.map((field, index) => {
                switch (field.type) {
                  case 100000000:
                    //simple text
                    return (
                      <SimpleText
                        isRequired={field.isRequired || false}
                        id={item.id}
                        key={index}
                        text={field.text || ""}
                        selected={getSelectedValue(
                          item.id,
                          field.customRegistrationFieldId
                        )}
                        control={(
                          action: InputFieldsActionsType,
                          payload: string
                        ) =>
                          control(
                            action,
                            field.customRegistrationFieldId,
                            item.id || "",
                            field.type!,
                            payload
                          )
                        }
                      />
                    )
                  case 100000001:
                    //booleanChoice

                    const selectedValue: string = getSelectedValue(
                      item.id,
                      field.customRegistrationFieldId
                    )

                    const selectedState: boolean | undefined =
                      selectedValue === "Yes"
                        ? true
                        : selectedValue === "No"
                        ? false
                        : undefined

                    return (
                      <BooleanChoice
                        key={index}
                        id={item.id}
                        isRequired={field.isRequired || false}
                        selected={selectedState}
                        text={field.text || ""}
                        groupName={"Middag" + index + item.name}
                        control={(
                          action: InputFieldsActionsType,
                          payload: string
                        ) =>
                          control(
                            action,
                            field.customRegistrationFieldId,
                            item.id || "",
                            field.type!,
                            payload
                          )
                        }
                      />
                    )
                  case 100000002:
                    //choose one or more
                    return (
                      <MultipleChoice
                        id={item.id}
                        isRequired={field.isRequired || false}
                        key={index}
                        text={field.text || ""}
                        selected={
                          getSelectedValue(
                            item.id,
                            field.customRegistrationFieldId
                          ).split("\n") || []
                        }
                        Choices={(field.choices || "").split("\n")}
                        control={(
                          action: InputFieldsActionsType,
                          payload: string
                        ) =>
                          control(
                            action,
                            field.customRegistrationFieldId,
                            item.id || "",
                            field.type!,
                            payload
                          )
                        }
                      />
                    )
                  case 100000003:
                    //choose one
                    return (
                      <SingleChoice
                        id={item.id}
                        isRequired={field.isRequired || false}
                        key={index}
                        text={field.text || ""}
                        selected={getSelectedValue(
                          item.id,
                          field.customRegistrationFieldId
                        )}
                        Choices={(field.choices || "").split("\n")}
                        control={(
                          action: InputFieldsActionsType,
                          payload: string
                        ) =>
                          control(
                            action,
                            field.customRegistrationFieldId,
                            item.id || "",
                            field.type!,
                            payload
                          )
                        }
                      />
                    )

                  default:
                    return <div></div>
                }
              })}
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Kursspm
