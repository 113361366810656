import React, { useContext } from "react"

import { InnmeldingContext } from "./dataStorage"

const Grunn = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const handleChange = (e: string) => {
    if (e.length > 250) return

    InnmeldingData.registrationReson[1](e)
  }

  return (
    <div className="my-6">
      <h2>Påmeldingsinformasjon</h2>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <p>
        Ettersom du allerede er påmeldt lignende kurs, ønsker vi å vite hvorfor
        du søker dette programmet - da vi trenger informasjon for å riktig
        behandle påmeldingen.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 my-6"></div>
      <textarea
        className="w-full"
        placeholder="Påmeldingsinformasjon"
        value={InnmeldingData.registrationReson[0]}
        onChange={e => handleChange(e.target.value)}
      ></textarea>
    </div>
  )
}
export default Grunn
