import React, { useEffect, useState } from "react"
// import { Disclosure } from "@headlessui/react"
import { navigate } from "gatsby"
import { ChevronDownIcon } from "@heroicons/react/outline"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { fetchApi, fetchApiAnonymous } from "../../../Utils/Api.utils"
import { Account, MyEmployments } from "../../../Utils/Entities"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import AktiveArbeidsforhold from "../../mypagecomponents/work/AktiveArbeidsforhold"
import InaktivArbeid from "../../mypagecomponents/work/InaktivArbeid"
import { loginRequest } from "../../security/authConfig"
import { useQuery } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import Modal from "../../common/modal/Modal"

const EmploymentContent = () => {
  const [showModal, setShowModal] = useState(false)
  const today = new Date()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [hasWork, setHasWork] = useState<boolean>(false)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (
          data?.find(
            work =>
              (work.employer !== undefined ||
                work.employerAlternative !== undefined) &&
              (work?.endDate === undefined ||
                new Date(work?.endDate).getTime() > today.getTime())
          )
        )
          setHasWork(true)
      },
      onError: _error => {},
    }
  )

  const checkForActiveWork = () => {
    if (hasWork === true) {
      setShowModal(true)
    } else {
      navigate("/app/profil/nyttarbeid/")
    }
  }

  return (
    <MyPageLayout props="Arbeidsforhold">
      {showModal && (
        <Modal setModalOpen={setShowModal}>
          <div>
            <p className=" mb-2 ">
              Du har ikke avsluttet ditt gamle arbeidsforhold. Trykk på "Avslutt
              arbeidsforhold" for å melde inn sluttdato på forrige
              arbeidsforhold.
            </p>
          </div>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Gå tilbake
            </button>
            <button
              type="submit"
              onClick={() => navigate("/app/profil/nyttarbeid/")}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Gå videre
            </button>
          </div>
        </Modal>
      )}
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <HeaderText text="Aktive Arbeidsforhold" />
          <AktiveArbeidsforhold active={true} />
        </div>
        <div className="flex justify-center py-4">
          <button
            type="submit"
            onClick={checkForActiveWork}
            className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
          >
            Nytt arbeidsforhold
          </button>
        </div>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <HeaderText text="Tidligere Arbeidsforhold" />
          <InaktivArbeid active={false} />
        </div>
      </div>
    </MyPageLayout>
  )
}
const Employments = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EmploymentContent />
    </MsalAuthenticationTemplate>
  )
}

export default Employments
