import React from "react"
import { SimpleTextType } from "./InputFieldsType"

const SimpleText = (props: SimpleTextType) => {
  const onChange = (e: any) => {
    props.control("update", e.target.value)
  }

  return (
    <div>
      <span className="font-bold">
        {props.text + (props.isRequired ? "*: " : ": ")}{" "}
      </span>
      <input type="text" defaultValue={props.selected} onChange={onChange} />
      <div className="h-8"></div>
    </div>
  )
}

export default SimpleText
