export const YesNoRadio = [
  { id: "0", title: "Nei" },
  { id: "1", title: "Ja" },
]

export const genders = [
  { value: 125600000, name: "Kvinne" },
  { value: 125600001, name: "Mann" },
  { value: 125600002, name: "Hen" },
]

export enum MemberType {
  None,
  Student,
  Psychologist,
  Pensioner,
}

export const MemberTypes = [
  { value: 0, name: "Student" },
  { value: 1, name: "Psykolog" },
  { value: 2, name: "Pensjonist" },
]

export const spesialistActivityTypes = [
  { name: "Eksternt kurs", id: 292460000 },
  { name: "Praksisattest", id: 292460001 },
  { name: "Skriftlig arbeid", id: 292460003 },
  { name: "Veiledningsattest", id: 292460004 },
  { name: "Veiledningskontrakt", id: 292460002 },
  { name: "Godkjenning av veileder", id: 292460005 },
  // only available for mandatory and spes. Psykoterapi
  { name: "Egenaktivitet", id: 292460007 },
  { name: "Konsulentsyklus", id: 292460008 },
]

// export const spesialistActivityTypesMandatory = [
//   { name: "Egenaktivitet", id: 292460007 },
// ]

export const spesialistActivityTypesMaintenance = [
  { name: "Vedlikeholdsaktivitet", id: 292460006 },
]

export const maintenanceActivityTypeTypes = [
  { name: "Deltagelse Kurs/Kongress", id: 292460000 },
  { name: "Annen aktivitet", id: 292460002 },
]

export const isSpecialistMethods = [
  { id: "0", title: "Ingen" },
  { id: "292460000", title: "Spesialist" },
  { id: "292460001", title: "Ikke spesialist" },
  { id: "292460002", title: "Delvis fullført spesialitet" },
]

export const whyDispensation = [
  { id: "0", title: "Ingen" },
  { id: "1", title: "Ingen spesialister tilgjengelig" },
  { id: "2", title: "Ingen spesialister med riktig spesialitet tilgjengelig" },
  { id: "3", title: "Andre grunner (spesifiser)" },
]

export const reasonDispensation = [
  { id: "0", title: "Ingen" },
  {
    id: "292460000",
    title: "Ingen spesialister tilgjengelig på arbeidsplassen",
  },
  {
    id: "292460001",
    title: "Ingen spesialister tilgjengelig i nærheten geografisk",
  },
  {
    id: "292460002",
    title:
      "Ingen spesialister med riktig spesialitet tilgjengelig på arbeidsplassen",
  },
  {
    id: "292460003",
    title:
      "Ingen spesialister med riktig spesialitet tilgjengelig i nærheten geografisk",
  },
  { id: "292460004", title: "Andre grunner (spesifiser)" },
]

export const reasonDispensation1 = [
  { id: "0", title: "Ingen" },
  {
    id: "292460000",
    title: "Ingen spesialister tilgjengelig på arbeidsplassen",
  },
  {
    id: "292460001",
    title: "Ingen spesialister tilgjengelig i nærheten geografisk",
  },
]

export const reasonDispensation2 = [
  { id: "0", title: "Ingen" },
  {
    id: "292460002",
    title:
      "Ingen spesialister med riktig spesialitet tilgjengelig på arbeidsplassen",
  },
  {
    id: "292460003",
    title:
      "Ingen spesialister med riktig spesialitet tilgjengelig i nærheten geografisk",
  },
]

export const typeWrittenWork = [
  { name: "Faglig arbeid", id: 292460000 },
  { name: "Utviklingsarbeid", id: 292460001 },
  { name: "Forskningsarbeid", id: 292460002 },
]

export const speakerResponse = [
  { id: "0", title: "Ingen" },
  { id: "292460000", title: "Godtatt" },
  { id: "292460001", title: "Tentativ" },
  { id: "292460002", title: "Avslått" },
]

export const consultingsCyclePhase = [
  { name: "Midtveis", id: "292460000" },
  { name: "Mot slutten", id: "292460001" },
]

export const TypePracticeConsultant = [
  { name: "Ledelse og lederutvikling", id: "292460000" },
  { name: "Organisasjonsutvikling", id: "292460001" },
  { name: "Teamutvikling", id: "292460009" },
  { name: "Veiledning og utviklingsarbeid", id: "292460002" },
  { name: "Personalutvikling", id: "292460003" },
  { name: "Konflikthåndtering", id: "292460004" },
  { name: "Arbeidsmiljøspørsmål", id: "292460005" },
  {
    name: "Konsultasjon, ovenfor individer, gruppeprosesser og på organisasjonsnivå",
    id: "292460006",
  },
  { name: "Bemanning, seleksjon og rekruttering", id: "292460007" },
  { name: "Annen type aktivitet (Spesifiser)", id: "292460008" },
]
