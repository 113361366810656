import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Account, MyEmployments } from "../../../Utils/Entities"
import Arbeid from "./Arbeid"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { useQuery } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"

const InaktivArbeid = props => {
  const contactId = props.id
  const [arbeid, setArbeid] = useState<MyEmployments[]>()
  const [accountName, setAccountName] = useState<string>()
  const [hasWork, setHasWork] = useState<boolean>(false)
  // const [accounts, setAccounts] = useState<Account[]>()

  const today = new Date()
  // today.setHours(0, 0, 0, 0)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (
          data.find(
            work =>
              (work.employer !== undefined ||
                work.employerAlternative !== undefined) &&
              work?.endDate != undefined &&
              new Date(work?.endDate).getTime() < today.getTime()
          )
        )
          setHasWork(true)
      },
      onError: _error => {},
    }
  )

  employmentQuery.data?.sort((a, b) => {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  })

  return employmentQuery.isLoading ? (
    <LoadingData />
  ) : (
    <>
      {hasWork ? (
        <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
          {employmentQuery.data?.map(work => {
            if (
              work.employer !== undefined ||
              work.employerAlternative !== undefined
            ) {
              // console.log(work?.endDate?.toDateString)
              if (work?.endDate != undefined) {
                const endDate = new Date(work?.endDate)
                if (endDate.getTime() < today.getTime())
                  return (
                    <div key={work?.id}>
                      <Arbeid work={work} />
                    </div>
                  )
              }
            }
          })}
        </div>
      ) : (
        <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Det finnes ingen oppføringer.
        </p>
      )}
    </>
  )
}

export default InaktivArbeid
